/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
// Components
import Footer from "./../components/Footer"
import Slider from "../components/Slider"
import Menu from "../components/Menu"
import { Link } from "gatsby"

const MainPage = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <div className="App">
        <div className="menu-nav">
          <div className="menu-nav__wrapper">
            <div id="ar-global">
              <div id="ar-page">
                <header>
                  <Menu />
                </header>
                <Slider />
                <main>
                  <section id="solutions">
                    <div>
                      <div className="ctr">
                        <span className="h4 nmgb"></span>
                        <h2 className="h1">Решения для ваших потребностей</h2>
                      </div>
                      <div className="quadra">
                        <div>
                          <Link to={"/dough-preparation"}>
                            <img
                              src="/images/preparation-vignette-285x350.jpg"
                              alt="preparation" />
                            <span className="flag f_Jaune"></span>
                            <div>
                              <h2>Приготовление теста</h2>
                              <span className="btnbis">Смотреть</span>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={"/dough-division-and-moulding"}>
                            <img
                              src="/images/machine-vignette-285x350.jpg"
                              alt="division" />
                            <span className="flag f_Vert"></span>
                            <div>
                              <h2>Формовка теста</h2>
                              <span className="btnbis">Смотреть</span>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={"/fermentation-and-conservation"}>
                            <img
                              src="/images/froid-vignette-285x350.jpg"
                              alt="froid" />
                            <span className="flag f_Bleu"></span>
                            <div>
                              <h2>Ферментация и консервация</h2>
                              <span className="btnbis">Смотреть</span>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={"/baking"}>
                            <img
                              src="/images/cuisson-vignette-285x350.jpg"
                              alt="cuisson" />
                            <span className="flag f_Rouge"></span>
                            <div>
                              <h2>Выпечка</h2>
                              <span className="btnbis">Смотреть</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="miseenavant"
                           style={{ backgroundImage: "url(/images/b-misenavant.png)" }}>
                    <div>
                      <div className="double">
                        <div>
                          <p><img
                            src="/images/stellar-600x820-1.png"
                            alt="Stellar-600x820" /></p>
                        </div>
                        <div>
                          <span className="h4 nmgb">маленький миксер, умеющий все</span>
                          <h2 className="h1">STELLAR</h2>
                          <p>Компактный и удобный, новый 20-литровый миксер Stellar
                            предназначен для всех мастеров, которые хотят приобрести
                            качественное профессиональное оборудование, менее громоздкое, но
                            столь же эффективное, как и традиционный миксер.</p>
                          <p>&nbsp;</p>
                          <p style={{ textAlign: "center" }}>
                            <Link className={"btn"}
                                  to={`/dough-preparation/planetary-mixer/planetary-mixers-stellar`}>
                              Узнать больше</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="savoirfaire">
                    <div>
                      <div className="double">
                        <div>
                          <p><img
                            src="/images/g-histoire-280x180.png"
                            alt="Histoire" /></p>
                          <span className="h4 nmgb">Bongard - ведущая компания</span>
                          <h2 className="h1">Bongard в мире</h2>
                        </div>
                        <div>
                          <p><img
                            src="/images/g-carte-800x400.png"
                            alt="Carte" /></p>
                          <p>
                                                    <span
                                                      className="picto salarie">115 инженеров и проектировщиков оборудования </span>
                            <span className="picto vfrance">350 менеджеров и техников во Франции</span>
                            <span
                              className="picto vmonde">450 менеджеров и техников в мире</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainPage
