import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

const Slider = () => (
    <div id="slider_img" className="carousel slide">
        <Carousel className="carousel-inner" showStatus={false} showIndicators={false} showThumbs={false}
                  autoPlay={true}
                  infiniteLoop={true}>
            <div>
                <img src="/images/slider-promo-v02-1440x660.jpg"
                     alt="Slider-promo-v02"/>
                <div className="txt">
                    <span className="h1">Bongard всегда на <br/> вашей стороне</span>
                </div>
            </div>
            <div className="carousel-item active">
                <img src="/images/slider-accueil-fr-11-1440x660.jpg"
                     alt="slider-accueil-FR-1(1)"/>
                <div className="txt">
                    <span className="h1">Лучшие технологии <br/> для лучшего хлеба</span>
                    <a href="#" className="btn">Наша история</a>
                </div>
            </div>
        </Carousel>
    </div>
);

export default Slider;
